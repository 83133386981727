@import "../../global.scss";

.singlePost {
  flex: 9;
}

.singlePostWrapper {
  padding: 20px;
  padding-right: 0;

  @include mobile {
    padding-left: 0;
  }
}

.singlePostImg {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  object-fit: cover;
}

.singlePostTitle {
  text-align: center;
  margin: 10px;
  font-family: "Lora", serif;
  font-size: 28px;

  @include mobile {
    text-align: left;
  }
}

.singlePostEdit {
  float: right;
  font-size: 16px;
}

.singlePostIcon {
  margin-left: 10px;
  cursor: pointer;
}

.singlePostIcon:first-child {
  color: rgb(41, 172, 41);
}

.singlePostIcon:last-child {
  color: red;
}

.singlePostInfo {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-family: "Varela Round", sans-serif;
  color: #b39656;
}

.singlePostAuthor {
  @include mobile {
    margin-left: 5px;
  }
}

.singlePostDate {
  @include mobile {
    margin-right: 5px;
  }
}

.singlePostDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;

  @include mobile {
    margin-left: 5px;
  }
}

.singlePostDesc::first-letter {
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
}

.singlePostSecondDesc {
  color: #666;
  font-size: 18px;
  line-height: 25px;

  @include mobile {
    margin-left: 5px;
  }
}

.singlePostSecondDesc::first-letter {
  margin-left: 20px;
}
