@import "../../global.scss";

.register {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("https://images.pexels.com/photos/4210784/pexels-photo-4210784.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");
  background-size: cover;
}

.registerTitle {
  font-size: 50px;
}

.registerForm {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.registerForm > label {
  margin: 10px 0;
}

.registerInput {
  padding: 10px;
  background-color: white;
  border: none;
}

.registerButton {
  margin-top: 20px;
  cursor: pointer;
  background-color: teal;
  border: none;
  color: white;
  border-radius: 10px;
  padding: 10px;

  @include mobile {
    padding: 15px;
    font-size: 15px;
  }
}

.registerLoginButton {
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: lightcoral;
  cursor: pointer;
  border: none;
  padding: 10px;
  color: white;
  border-radius: 10px;

  @include mobile {
    padding: 15px;
    font-size: 15px;
  }
}
