@import "../../global.scss";

.about {
  flex: 3;
  height: fit-content;
  margin: 20px;
  padding-bottom: 30px;
  background-color: #fdfbfb;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutTitle {
  margin: 10px;
  padding: 5px;
  width: 80%;
  border-top: solid 1px #a7a4a4;
  border-bottom: solid 1px #a7a4a4;
  text-align: center;
  font-family: "Varela Round", sans-serif;
  font-size: 12px;
  line-height: 19px;
  color: #222222;
  font-weight: 600;
}

.aboutItem > img {
  margin-top: 15px;
  width: 320px;
  height: 400px;
}

.aboutItem > p {
  padding: 30px;
  margin-left: 33%;
  margin-right: 33%;

  @include mobile {
    margin-left: 0;
    margin-right: 0;
  }
}

.aboutList {
  list-style-type: none;
  margin-bottom: 30px;
}

.aboutListItem {
  display: inline-block;
  width: 50%;
  margin-top: 15px;
  cursor: pointer;
}

.aboutSocial {
  margin-top: 15px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutIcon {
  font-size: 16px;
  margin-left: 10px;
}

.endingTxt {
  @include mobile {
    display: none;
  }
}
