@import "../../global.scss";

.write {
  padding-top: 50px;
}

.writeImg {
  margin-left: 150px;
  width: 70vw;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;

  @include mobile {
    margin-left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 300px;
  }
}

.writeForm {
  position: relative;
}

.writeFormGroup {
  margin-left: 150px;
  display: flex;
  align-items: center;

  @include mobile {
    margin-left: 50px;
  }
}

.writeIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: gray;
}

.writeInput {
  font-size: 30px;
  border: none;
  padding: 20px;
  width: 70vw;

  @include mobile {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

.writeInput:focus {
  outline: none;
}

.writeText {
  font-size: 20px;
  height: 100vh;
}

.writeSubmit {
  position: absolute;
  top: 20px;
  right: 50px;
  color: white;
  background-color: green;
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;

  @include mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
