@import "../../global.scss";

.posts {
  flex: 9;
  display: flex;
  flex-wrap: wrap;
  margin: 20px;

  @include mobile {
    margin-left: 0;
  }
}
